import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import util from '@/utils/util'

Vue.prototype.$util = util;

let base = 'https://ccf.pkucxpl.com/'

Vue.prototype.$uploadUrl = base + 'api/uploadWorksSpecifications';

Vue.prototype.$uploadCode = base + 'api/uploadCompetitionCode';

Vue.prototype.$uploadVideoUrl = base + 'api/uploadWorksVideo';



Vue.config.productionTip = false

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

import VueScrollTo from "vue-scrollto";

Vue.use(VueScrollTo);

Vue.use(ElementUI);

// 富文本
// 全局挂载 VueQuillEditor
import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

Vue.use(VueQuillEditor)

Vue.filter('vuedate', function(value) {
	let appointDate = /\d{4}-\d{1,2}-\d{1,2}/g.exec(value)[0];
	appointDate = appointDate.split('-')[1]+"."+appointDate.split('-')[2]
  return appointDate
});
Vue.filter('vuedateAll', function(value) {
	let appointDate = /\d{4}-\d{1,2}-\d{1,2}/g.exec(value)[0];
	appointDate = appointDate.split('-')[0]+"."+appointDate.split('-')[1]+"."+appointDate.split('-')[2]
  return appointDate
});

Vue.filter('filename', function(value) {
	let val;
	if(value.indexOf('/')>-1) {
		val =  value.split('/')[1]
	}else {
		val = value
	}
  return val
});

// 弹窗闪烁
// ElementUI.Dialog.props.lockScroll.default = false;

// 全局样式
import '@/assets/css/reset.css'


// 正式环境清除所有console.log
if (process.env.NODE_ENV === 'production') {
  if (window) {
    window.console.log = function () {};
  }
}





//导航守卫（点击登录后，返回上一页面）
router.beforeEach((to, from, next) => {
		if(to.meta) {
			document.title = to.meta.title+"-CCF计算经济学大赛"
		} else {
			document.title = ''
		}
//登录页
  if (to.path == '/login') {
		if(from.path == '/reg' || from.path == '/resetpsd' || from.path == '/login') {
			localStorage.setItem("preRoute", '/')
		} else {
			//保存当前路由
			localStorage.setItem("preRoute", router.currentRoute.fullPath)
		} 
  }
	document.body.scrollTop = 0
	window.pageYOffset = 0
	document.documentElement.scrollTop = 0
  next()
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
