import axios from "axios";
import { MessageBox, Message, Loading } from "element-ui";
import store from "@/store";
import { getToken } from "@/utils/auth";
import Cookies from "js-cookie"
import router from "@/router"

// axios.defaults.withCredentials = true;


// create an axios instance
const service = axios.create({
  baseURL: 'https://ccf.pkucxpl.com/api/', // url = base url + request url   http://inno.pkucxpl.com/api
  // withCredentials: true, // send cookies when cross-domain requests
  // timeout: 5000, // request timeout   接口超时时间提醒，后期根据需求判断是否开放
});


import pageLoading from '../assets/css/pageLoading.css'

// request interceptor
service.interceptors.request.use(
  (config) => {
		// showLoaing()
    if(Cookies.get("authorization")){
      config.headers["authorization"] = Cookies.get("authorization")
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);


// response interceptor
service.interceptors.response.use(
  (response) => {
    const res = response.data;
		const code = res.code
		if(code!=1) {
			// hideLoading()
			if(code==0) {
				store.commit('LOGOUT', '')
				return false;
			}
			if(res.msg=='该用户尚未参与该比赛' || res.msg=="帖子暂无评论") {
				return Promise.reject(res.msg || 'Error')
			}
			if(code==3005 || code==3004 || code==2001) {
				return Promise.reject(res.msg || 'Error')
			}
			Message({
			  message: res.msg || 'Error',
			  type: 'error',
			  duration: 5 * 1000
			})
			return Promise.reject(res.msg || 'Error')
		}else {
			// hideLoading()
			return res
		}
  },
  (error) => {
    console.log("err" + error); // for debug
    Message({
      message: error.message,
      type: "error",
      duration: 5 * 1000,
    });
    return Promise.reject(error);
  }
);

let loading;
let loadingCount = 0;
function start() {
  loading = Loading.service({
    lock: true,
    text: '',
    background: 'rgba(0,0,0,0.7)',
		spinner: 'loaderLoading'
  })
};
function end() {
  loading.close();
}
function showLoaing() {
  if (loadingCount == 0) {
    start();
  };
  loadingCount++
};

function hideLoading() {
  loadingCount--;
  if (loadingCount == 0) {
    end()
  }
}

export default service;
