<template>
	<div class="">
		<!-- 分类 -->
		<div class="content pc">
			<!-- 内容 -->
			<div class="navcon containter" v-if="info">
				<!-- 左侧 -->
				<div class="left" :class="{ left_nav: isFixedNav }">
					<p :class="actindex==index ? 'act' : ''" v-for="(item,index) in info.competitionContent" :key="item.title"
						@click="scl('scl'+index,index, id)"><span class="hover">{{ item.title }}</span></p>
				</div>
				<div class="left" v-if="isFixedNav"></div>
				<!-- 中间 -->
				<div class="center">
					<div class="top scl0">
						<div>
							<p class="title">{{ info.competitionName }}</p>
							<p class="time">赛事时间：{{ info.startDated }} ~ {{ info.endDated }}</p>
							<p class="price">奖励：{{ info.rewards }}</p>

							<div class="btn-block">
					<!-- isThirdParty 0代表自有平台，1代表第三方赛事 -->
					<div v-if="info.isThirdParty==0">
						<!-- 用户按钮 -->
						<!--
							competitionState
							0  报名中
							1  作品提交中
							2  实训比赛中
							3  结束？
						 -->
						<!-- userstate.state 
							0已报名，1已提交，2已实训
						-->
						<div>
							<!-- 未登录 -->
							<div v-if="!$store.state.token">
								<!-- 是否在报名时间内 -->
								<div v-if="$util.getTime(info.competitionProcess[0].startDated,info.competitionProcess[0].endDated)">
									<p class="baoming" @click="bmClick">报名参赛</p>
								</div>
							</div>
							<!-- 已登录 -->
							<div v-else>
								<!-- 未报名 -->
								<div v-if="userstate.state<0">
									<!-- 可以报名 -->
									<div v-if="$util.getTime(info.competitionProcess[0].startDated,info.competitionProcess[0].endDated)">
										<p class="baoming" @click="bmClick">报名参赛</p>
									</div>
								</div>
								<div v-else>
									<!-- 已报名 -->
									<!-- checkState  0审核中 1审核通过 2审核不通过 -->
									<div v-if="userstate.checkState==0">
										<p class="baoming opacity3">报名审核中</p>
									</div>
									<div v-else-if="userstate.checkState==2">
										<p class="finish">报名未通过</p>
									</div>
									<div v-else>
										<div v-if="userstate.state==0">
											<!-- 初赛开启 s -->
											<div v-if="info.firstCompetitionState==1">
												<!-- 在初赛时间内 -->
												<div v-if="$util.getTime(info.competitionProcess[1].startDated,info.competitionProcess[1].endDated)">
													<p class="baoming" @click="tjzpClick">提交作品</p>
												</div>
												<!-- 不在初赛时间内 -->
												<div v-else>
													<p class="finish"><i class="el-icon-success"></i>已报名</p>
												</div>
											</div>
											<!-- 初赛开启 e -->
											<!-- 初赛未开启 s -->
											<div v-else>	
												<!-- 决赛开启 s -->
												<div v-if="info.finalCompetitionState==1">
													<!-- 在决赛时间内 -->
													<div v-if="$util.getTime(info.competitionProcess[1].startDated,info.competitionProcess[1].endDated)">
														<p class="baoming" @click="go">开始比赛</p>
													</div>
													<!-- 不在决赛时间内 -->
													<div v-else>
														<p class="finish"><i class="el-icon-success"></i>已报名</p>
													</div>
												</div>
												<!-- 决赛开启 e -->
											</div>
											<!-- 初赛未开启 e -->
										</div>
					
										<!-- 已提交 -->
										<div v-else-if="userstate.state==1">
											<!-- 决赛开启 s -->
											<div v-if="info.finalCompetitionState==1">
												<!-- 在决赛时间内 -->
												<div v-if="$util.getTime(info.competitionProcess[2].startDated,info.competitionProcess[2].endDated)">
													<p class="baoming" @click="go">开始比赛</p>
												</div>
												<!-- 不在决赛时间内 -->
												<div v-else>
													<p class="finish"><i class="el-icon-success"></i>已提交作品</p>
												</div>
											</div>
											<!-- 决赛开启 e -->
										</div>
					
										<!-- 已实训 -->
										<div v-else-if="userstate.state==2">
											<div>
												<p class="finish"><i class="el-icon-success"></i>已完成赛事</p>
											</div>
										</div>
					
									</div>
					
								</div>
							</div>
					
						</div>
						
					</div>
					
						<!-- "参赛指南" entryKit -->
						<div v-if="info.entryKit" class="entry">
							<a :href="info.entryKitUrl"
								target="_blank"
								v-if="info.entryKitUrl"
							>{{ info.entryKit }}</a>
							<a href="javascript:void(null);"
								v-else
							>{{ info.entryKit }}</a>
						</div>
					
					</div>
						</div>
						<img :src="info.pic" alt="">
					</div>
					
					
					<!-- 内容 -->
					<div class="step" :class="index>0 ? 'scl'+index : ''" v-for="(item,index) in info.competitionContent"
						:key="'step'+item.title" :ref="'indexnr'+index">
						<p class="step_title">{{ item.title }}</p>
						<div class="step_con" v-html="item.content"></div>
					</div>
				</div>
				<!-- 右侧 -->
				<!-- 右侧 -->
				<div class="right">
					<div class="right_block">
						<p class="subtit">
							<span class="iconfont icon-bisaihuodong"></span>
							{{ info.competitionStateMsg }}
						</p>
						<div class="timeline">
							<p class="date">{{ info.startDated | vuedate }}</p>
							<div class="stepline">
								<!-- 点位 -->
								<div class="dot">
									<div class="zw" v-for="item in getTimedotAll()">
										<p>
											<span class="dian iconfont"
												:class="{'icon-Raidobox-xuanzhong' : shijianbi(i), 'icon-dian' : !shijianbi(i)}"
												v-for="i in getTimedot()" :key="'dot'+i" v-if="i==item"></span>
										</p>
									</div>
								</div>
								<div class="process" :style="{
									height: process }">
								</div>
								<!-- 三个时间段 -->
								<div class="process1" :style="styleAll.style1">
									<p></p>
									<div class="process1Div">
										{{ info.competitionProcess[0].startDated | vuedate }} ~
										{{ info.competitionProcess[0].endDated | vuedate }}
										<span>赛事报名<i class="iconfont icon-wancheng" v-if="userstate.state>-1"></i></span>
									</div>
								</div>
								<div class="process2" :style="styleAll.style2" v-if="info.firstCompetitionState==1">
									<p></p>
									<div class="process2Div" :style="style1 || {}">
										{{ info.competitionProcess[1].startDated | vuedate }} ~
										{{ info.competitionProcess[1].endDated | vuedate }}
										<span>赛事初赛<i class="iconfont icon-wancheng" v-if="userstate.state>=1"></i></span>
									</div>
								</div>
								<div class="process3" :style="styleAll.style3" v-if="info.finalCompetitionState==1">
									<p></p>
									<div class="process3Div" :style="style2 || {}">
										<div>{{ info.firstCompetitionState==1 ? info.competitionProcess[2].startDated : info.competitionProcess[1].startDated | vuedate }} ~
											{{ info.firstCompetitionState==1 ? info.competitionProcess[2].endDated : info.competitionProcess[1].endDated | vuedate }}</div>
										<span>赛事决赛<i class="iconfont icon-wancheng" v-if="userstate.state>=2"></i></span>
									</div>
								</div>
							</div>
							<p class="date">{{ info.endDated | vuedate }}</p>
						</div>
					</div>
					
					<div class="right_block rmt30" v-if="info.competitionData.length>0">
						<p class="subtit">
							<span class="iconfont icon-icon"></span>
							比赛资料
						</p>
						<el-tooltip class="item" effect="dark" :content="'下载'+item['dataFile' + (index + 1) + 'Name']" placement="top-start" v-for="(item,index) in info.competitionData" :key="item.downloadUrl">
							<p class="downloadbtn" @click="downloadCode(item)">{{ item['dataFile' + (index + 1) + 'Name'] }}</p>
						</el-tooltip>
					</div>
				</div>
						

			</div>
		</div>


		<!-- 主题赛事 - Mobile -->
		<div class="mobiles" v-if="info">
			<!-- 主题赛事 Mobile -->
			<div class="mobile trends containter">
				<div class="list">
					<img :src="info.pic" alt="">
					<p class="title">{{ info.competitionName }}</p>
					<p class="time">赛事时间：{{ info.startDated }} ~ {{ info.endDated }}</p>
					<p class="time">奖励：{{ info.rewards }}</p>
		
					<div v-if="info.competitionState==0">
						<p v-if="userstate && userstate.state==0" class="finish"><i class="el-icon-success"></i>{{ userstate.stateMsg }}</p>
						<p class="baoming" @click="bmClick">报名参赛</p>
					</div>
					<div v-if="info.competitionState==1">
						<p v-if="userstate && userstate.state==1" class="finish"><i class="el-icon-success"></i>{{ userstate.stateMsg }}</p>
						<p v-else class="baoming" @click="tjzpClick">提交作品</p>
					</div>
					<div v-if="info.competitionState==2">
						<p v-if="userstate && userstate.state==2" class="finish"><i class="el-icon-success"></i>{{ userstate.stateMsg }}</p>
						<p v-else class="baoming" @click="go">开始比赛</p>
					</div>
				</div>
				<div class="bz">
					<div class="title">
						<img :src="require('@/assets/bj.png')" alt="">
						<p>{{ info.competitionStateMsg }}</p>
						<span @click="showDetail = true">查看赛程详情</span>
					</div>
					<div class="buzhou">
						<div class="step">
							<div class="step1">
								<span class="iconfont icon-Raidobox-xuanzhong"></span>
								<p></p>
							</div>
							<div class="step1">
								<span class="iconfont icon-Raidobox-xuanzhong"></span>
								<p></p>
							</div>
							<div class="step1">
								<span class="iconfont icon-Raidobox-xuanzhong"></span>
								<p></p>
							</div>
							<div class="step1">
								<span class="iconfont icon-Raidobox-xuanzhong"></span>
								<p></p>
							</div>
						</div>
						<div class="stepline"></div>
					</div>
				</div>
				<div class="nr">
					<div v-for="(item,index) in info.competitionContent" :key="'step'+item.title">
					<p class="title">{{ item.title }}</p>
					<div class="xq" v-html="item.content"></div>
					</div>
				</div>
			</div>
			
			<div class="detailbz" v-if="showDetail">
				<span class="iconfont icon-guanbi" @click="showDetail = false"></span>
				<div class="title">{{ info.competitionStateMsg }}</div>
				<div class="timeline containter">
					<p class="date">{{ info.startDated | date }}</p>
					<div class="line act" v-for="item in info.competitionProcess" :key="'process'+item.title">
						<div :style="pro(item.startDated,item.endDated)"></div>
						<span class="iconfont icon-Raidobox-xuanzhong"></span>
						<p>{{ item.startDated }} ~ {{ item.endDated }}</p>
						<p class="sub">{{ item.title }}</p>
					</div>
					<p class="date"><span class="iconfont icon-danxuanxuanzhong"></span>{{ info.endDated | date }}</p>
				</div>
			</div>
			
			
		</div>
		<!-- -->

		<!-- 报名参赛 -->
		<div v-if="info">
			<el-dialog 
				title="报名参赛" 
				:visible.sync="dialogVisible" 
				width="50%"
				:close-on-click-modal="false"
			>
				<div class="dia">
					<p class="tit">{{ info.competitionName }}</p>
					<div class="input">团队名称*</div>
					<el-input v-model="baomingForm.teamName"></el-input>
					<div class="input">参赛组别</div>
					<el-radio-group v-model="baomingForm.group">
						<el-radio :label="0">高校组</el-radio>
						<el-radio :label="1">企业组</el-radio>
					</el-radio-group>
					<div class="input">公司/高校</div>
					<el-input v-model="baomingForm.schoolCompanyName"></el-input>
					<div class="input" v-if="info.isShowFields!=0">天池ID*</div>
					<el-input v-model="baomingForm.extendField" v-if="info.isShowFields!=0"></el-input>
					<div class="input" v-if="info.isShowReferrer!=0">推荐人*</div>
					<el-input v-model="baomingForm.referrer" v-if="info.isShowReferrer!=0"></el-input>
					<div class="input">队长</div>
					<el-input v-model="baomingForm.captainName"></el-input>
					<div v-for="(item,index) in user" v-if="user.length>0" class="adduser">
						<div>
							<div class="inputdiv">
								<span>队员姓名*</span>
								<el-input v-model="item.name"></el-input>
							</div>
							<div class="inputdiv">
								<span>手机号*</span>
								<el-input v-model="item.tel"></el-input>
							</div>
						</div>
						<i class="el-icon-delete-solid" @click="del(index)"></i>
					</div>
					<el-button class="bn" @click="add">+ 添加团队成员</el-button>
				</div>
				<span slot="footer" class="dialog-footer">
					<div class="left">
						<el-checkbox v-model="checked">我已阅读并同意</el-checkbox>
						<p class="xyclass" @click.stop="showXiyi">参赛协议</p>
					</div>
					<div>
						<el-button class="can" @click="dialogVisible = false">取消</el-button>
						<el-button class="sub" type="primary" @click="editCompetitionTeam">立即报名<span v-if="info.isPay==1">￥{{ info.payFee }}</span></el-button>
					</div>
				</span>
			</el-dialog>
		</div>
		
		<!-- 参赛协议 -->
		<div v-if="xieyiDia">
			<el-dialog title="参赛协议" :visible.sync="xieyiDia" width="50%" :close-on-click-modal="false" class="xieyi">
				<div v-html="info.disclaimer" class="xiyihtml"></div>
			</el-dialog>
		</div>
		
		<!-- 微信支付 -->
		<div v-if="payVisible">
			<el-dialog 
				title="微信支付" 
				:visible.sync="payVisible" 
				width="40%"
				:close-on-click-modal="false"
			>
				<div class="dia pay">
					<div id="qrcode" ref="qrcode"></div>
					<div class="text">
						<p>请使用微信扫码</p>
						<p>支付成功后完成报名</p>
						<div class="an" @click="payfinish">已完成报名</div>
					</div>
				</div>
			</el-dialog>
		</div>
		
		<!-- 提交参赛作品 -->
		<div v-if="subVisible">
			<el-dialog title="提交参赛作品" :visible.sync="subVisible" width="60%" :close-on-click-modal="false">
				<div class="dia">
					<p class="tit">{{ info.competitionName }}</p>
					<div class="input">作品名称*</div>
					<el-input v-model="worksForm.worksName"></el-input>
					<div class="input">作品描述*</div>
					<el-input v-model="worksForm.workDescription" type="textarea" :rows="3"></el-input>
					<div class="input">作品方案*</div>
					<div class="upd1" v-if="worksForm.processDocumentName">
						<img :src="require('@/assets/file.png')" alt="">{{ worksForm.processDocumentName }}
						<i class="el-icon-close" @click="delpro"></i>
					</div>
					<el-upload class="upload-demo" 
						v-show="!worksForm.processDocumentName"
						:before-upload="beforeAvatarUpload"
						:action="$uploadUrl"
						:headers="{authorization: this.$store.state.token}"
						:data="{userId: this.$store.state.userId}"
						:limit="1"
						:on-success="onSuccess"
						:on-error="onError"
						ref="zuopin"
						:show-file-list = 'false'
					>
						<div class="upd">
							<p><i class="el-icon-circle-plus"></i>上传参赛作品</p>
							<div slot="tip" class="el-upload__tip">支持所有文件格式，文件大小不超过30M</div>
						</div>
					</el-upload>
					<div class="pc">
						<div class="text">请按照作品模板要求填写并上传，作品方案将作为初步评审的评分依据<span><a :href="downlink" download>下载作品模板</a></span></div>
					</div>
					<div class="mobiles">	
						<div class="text">请按照作品模板要求填写并上传，作品方案将作为初步评审的评分依据
							<p><span><a :href="downlink" download>下载作品模板</a></span></p>
						</div>
					</div>
					<!-- <div class="input">参赛免责声明</div>
					<div class="mianze">
						*参加大赛的选手和各企业机构，所提交的作品或文件内容，如涉及企业敏感信息、保密信息、机密数据等不可外泄的内容，请在提交和发布前自行脱敏处理，一旦泄密或产生任何不良影响和后果，大赛组织方均不承担任何责任，请知悉。<br>
						*作品须为原创作品，不得抄袭、模仿或侵害他人知识产权行为。参赛作品须不存在任何知识产权纠纷或争议。<br>
						*参赛者拥有作品的所有权，参赛者同意授予组委会对参赛作品进行展览、宣传、发布等权益。所有稿件不予退稿，请自留底稿。<br>
						*若产生知识产权纠纷或争议，由参赛者本人或其组织承担相应责任。</div> -->
						
						<div class="input">作品视频*</div>
						<div class="upd1" v-if="worksForm.processVideoName">
							<img :src="require('@/assets/file.png')" alt="">{{ worksForm.processVideoName }}
							<i class="el-icon-close" @click="deldemo"></i>
						</div>
						<el-upload class="upload-demo"
							:action="$uploadVideoUrl"
							v-show="!worksForm.processVideoName"
							:before-upload="beforeAvatarUpload1"
							:headers="{authorization: this.$store.state.token}" :data="{userId: this.$store.state.userId}" :limit="1"
							ref="zuopin"
							:show-file-list = 'false'
							:on-success="onSuccess1"
							:on-error="onError1"
							>
							<div class="upd">
								<p><i class="el-icon-circle-plus"></i>上传参赛作品视频</p>
								<div slot="tip" class="el-upload__tip">支持所有文件格式，文件大小不超过30M</div>
							</div>
						</el-upload>
				</div>

				<span slot="footer" class="dialog-footer">
					<div class="ml">
						<el-button class="can" @click="subVisible = false">取消</el-button>
						<el-button class="sub" type="primary" @click="submitCompetitionWorks">提交作品</el-button>
					</div>
				</span>
			</el-dialog>
		</div>
		
		<!-- 开始比赛 -->
		<div v-if="matchVisible">
			<el-dialog title="开始比赛" :visible.sync="matchVisible" width="50%"
				:close-on-click-modal="false" class="mat">			
				<div class="match" v-html="matchInfo.finalCompetitionDesc"></div>
				<div class="matchbtn">
					<!-- isSubmitCode：0未提交 1已提交 -->
					<p @click="subcodefn">{{ userstate.isSubmitCode==0 ? '提交比赛代码' : '已提交比赛代码' }}</p>
					<p v-if="matchInfo.trainingPlatform"><a :href="matchInfo.trainingPlatform" target="_blank">进入比赛环境</a></p>
				</div>
			</el-dialog>
		</div>
		
		<!-- 提交比赛代码 -->
		<div v-if="codeVisible">
			<el-dialog title="提交比赛代码" :visible.sync="codeVisible" width="50%"
				:close-on-click-modal="false" class="code">
				<div class="dia">
					<div v-if="codeUrl">
						<div class="upcode">
							<i class="el-icon-document"></i>
							<p>{{codeUrl | filename}}</p>
							<!-- <span @click="delCode">重新提交</span> -->
							<i class="el-icon-close" @click="delCode"></i>
						</div>
					</div>	
					<el-upload class="upload-demo" :before-upload="codebefor"
						:action="$uploadCode"
						v-show="!codeUrl"
						ref="upload"
						:headers="{authorization: $store.state.token}" :data="{userId: $store.state.userId,competitionId:id,teamId:userstate.teamId}" :limit="1"
						:on-success="codeSuccess">
						<div class="upd">
							<p><i class="el-icon-circle-plus"></i>上传比赛代码文件</p>
							<div slot="tip" class="el-upload__tip">文件格式推荐为压缩文件，文件大小不超过30M</div>
						</div>
					</el-upload>
				</div>
		
				<span slot="footer" class="dialog-footer">
					<div class="codef">
						<p>*重复提交，系统会自动覆盖并更新为最新提交的代码文件</p>
						<el-button class="sub" type="primary" @click="subCode">提交</el-button>
					</div>
				</span>
			</el-dialog>
		</div>

	</div>
</template>

<script>
	import {
		competitionDetail,
		editCompetitionTeam,
		downloadWorksTemplate,
		uploadWorksSpecifications,
		submitCompetitionWorks,
		updateTeamByPaySuccess,
		getUserCompetitionState,
		getJumpTrainingUrl,
		getCompetitionTeam,
		getCompetitionTraining,
		addDownloadLog
	} from "@/api/api.js"
	import QRCode from 'qrcodejs2'
	const pattern = /^1[3456789]\d{9}$/;
	export default {
		props:['id','actindex','tdata','isFixedNav'],
		components:{
			QRCode
		},
		data() {
			return {
				tjnum: -1,
				codeVisible: false,
				matchVisible: false,
				dialogVisible: false,
				checked: false,
				subVisible: false,
				fileList: [],
				subVisible2: false,
				info: null,
				user: [],
				baomingForm: {
					userId: parseInt(this.$store.state.userId),
					competitionId: '',
					teamName: '',
					group: 0,
					schoolCompanyName: '',
					captainName: this.$store.state.userName,
					member: [],
					referrer: '',
					isShowFields: ''
				},
				payVisible: false,
				paycode: '',
				input: '',
				worksForm: {
					userId: parseInt(this.$store.state.userId),
					competitionId: '',
					worksName: '',
					workDescription: '',
					processDocumentName: '',
					processDocumentUrl: '',
					processVideoName:'',
					processVideoUrl: ''
				},
				file: null,
				userstate: {
					state: -1
				},
				showDetail: false,
				teamid: '',
				style1: null,
				style2: null,
				styleAll: {
					style1: null,
					style2: null,
					style3: null
				},
				process: null, // 当前时间进度
				xieyiDia: false, // 协议内容弹窗
				codeUrl: '',
				matchInfo: {},
				loading: null
			}
		},
		watch: {
			dialogVisible(val) {
				if(val) {
					this.baomingForm = {
						userId: parseInt(this.$store.state.userId),
						competitionId: '',
						teamName: '',
						group: 0,
						schoolCompanyName: '',
						captainName: this.$store.state.userName,
						member: [],
						referrer: ''
					}
					this.user = []
					this.checked = false
				}
			},
		},
		filters: {
			date(val) {
				let str = val.slice(5);
				return val
			}
		},
		mounted() {
			this.info = this.tdata
			this.setTime()
			if(this.$store.state.token) {
				this.getUserCompetitionState()
				this.getmatchInfo()
			}
		},
		methods: {
			delpro() {
				console.log('》》》')
				this.$refs.zuopin.clearFiles()
				this.worksdetail.processDocumentName = ''
				this.worksdetail.processDocumentUrl = ''
			},
			deldemo() {
				this.$refs.demo.clearFiles()
				this.worksdetail.processVideoName = ''
				this.worksdetail.processVideoUrl = ''
			},
			// 删除比赛代码
			delCode() {
				console.log('删除比赛代码')
				this.tjnum++
				this.$refs.upload.clearFiles();
				this.codeUrl = ''
			},
			// 上传代码前验证
			codebefor() {
				
			},
			// 开始比赛接口
			getmatchInfo() {
				getCompetitionTraining({
					userId: this.$store.state.userId,
					competitionId: this.id
				}).then(res => {
					console.log('开始比赛接口',res.data)
					this.matchInfo = res.data
					this.codeUrl = res.data.competitionCode
				})
			},
			// 打开开始比赛窗口
			subcodefn() {
				if(this.matchInfo.finalCodeState!=1) {
					return this.$message.error('当前赛事不允许提交代码')
				}
				this.codeVisible = true
			},
			// 提交赛事代码
			codeSuccess(res) {

				this.codeUrl = res.name
			},
			subCode() {
				if(this.codeUrl) {
					this.$refs.upload.submit();
					this.codeVisible = false;
				}else {
					this.$message.error('请上传比赛代码文件！')
				}
			},
			// 参赛协议
			showXiyi() {
				if(this.info.disclaimer) {
					this.xieyiDia = true
				}
			},
			// 设置时间轴
			setTime() {
				let that = this
				this.$nextTick(() => {
					window.addEventListener('scroll', that.handleScrollx, true)
					that.process = this.timeprocess()
					that.styleAll = {
						style1: that.timeprocessTop(0),
						style2: that.timeprocessTop(1),
						style3: that.timeprocessTop(2)
					}
					that.timeprocessDiv(1)
					that.timeprocessDiv(2)
				})
			},
			// 点击报名参赛按钮
			bmClick() {
				if(!this.$store.state.token) {
					this.$router.push('/login')
					return false;
					// return this.$message.error('您还未登录，请登录后操作！')
				}
				this.dialogVisible = true;
			},
			// 点击提交作品按钮
			tjzpClick() {
				if(!this.$store.state.token) {
					this.$router.push('/login')
					return false;
					// return this.$message.error('您还未登录，请登录后操作！')
				}
				this.subVisible = true;
			},
			// 用户状态
			getUserCompetitionState() {
				getUserCompetitionState({
					userId: this.$store.state.userId,
					competitionId: this.id
				}).then(res => {
					console.log('用户状态',res.data)
					this.userstate = res.data
					this.teamid = res.data.teamId
				})
			},
			// 开始比赛
			go() {
				if (!this.$store.state.token) {
					return this.$router.push('/login')
				}
				this.matchVisible = true;
			},
			// 比赛资料下载
			downloadCode(url) {
				if (!this.$store.state.token) {
					return this.$router.push('/login')
				}
				if(this.userstate.state<0) {
					return this.$message.error('请先报名后下载资料')
				}
				if(this.userstate.checkState==0) {
					return this.$message.error('报名审核中，请审核通过后下载资料')
				}
				if(this.userstate.checkState==2) {
					return this.$message.error('报名审核失败，无法下载资料')
				}
				addDownloadLog({
					userId: this.$store.state.userId * 1,
					competitionId: this.id,
					teamId: this.teamid,
					downloadUrl: url.downloadUrl
				}).then(res => {
					console.log('下载',res)
				})
				
				let a_link = document.createElement('a')
				a_link.href = url.downloadUrl
				document.body.appendChild(a_link)
				a_link.click()
			},
			// 说明书模板
			downloadWorksTemplate() {
				downloadWorksTemplate({
					userId: this.$store.state.userId,
					competitionId: this.id
				}).then(res => {
					console.log('说明书模板',res.data)
					let a_link = document.createElement('a')
					a_link.href = res.data.downloadUrl
					document.body.appendChild(a_link)
					a_link.click()
				})
			},
			// 上传说明书
			beforeAvatarUpload(file) {
				this.file = file
				const isLt2M = file.size / 1024 / 1024 < 30;
				if (!isLt2M) {
					this.$message.error('上传作品大小不能超过30MB');
				}
				return isLt2M;
			},
			// 上传视频
			beforeAvatarUpload1(file) {
				console.log(file.type)
				const isMP4 = file.type === 'video/mp4';
				const isLt500M = file.size / 1024 / 1024 < 500;
			
				if (!isMP4) {
					this.$message.error('请上传正确格式的文件!');
				} else if (!isLt500M) {
					this.$message.error('上传作品视频大小不能超过500MB!');
				} else {
					this.loading = this.$loading({
						lock: true,
						text: '作品视频上传中',
						spinner: 'el-icon-loading',
						background: 'rgba(0, 0, 0, 0.7)'
					});
				}
				return isMP4 && isLt500M;
			},
			onError() {
				this.$message.error('上传失败')
				this.delpro()
			},
			onError1() {
				this.$message.error('上传失败')
				this.deldemo()
				this.loading.close();
			},
			// 
			onSuccess(file) {
				let data = file.data
				console.log('onSuccess',file)
				this.$message.success('上传成功')
				this.worksForm.processDocumentName = data.fileName
				this.worksForm.processDocumentUrl = data.filePath
			},
			onSuccess1(file) {
				let data = file.data
				this.$message.success('上传成功')
				console.log('onSuccess', file)
				this.worksForm.processVideoName = data.fileName
				this.worksForm.processVideoUrl = data.filePath
				this.loading.close();
			},
			// 提交作品
			submitCompetitionWorks() {
				console.log('this.worksForm',this.worksForm)
				submitCompetitionWorks({
					...this.worksForm,
					userId: this.$store.state.userId*1,
					competitionId: this.id*1
				}).then(res => {
					this.$message.success(res.msg)
					this.getUserCompetitionState()
					this.subVisible = false
				})
			},
			// 完成报名
			payfinish(){
				updateTeamByPaySuccess({
					userId: this.$store.state.userId,
					competitionId: this.id,
					teamId: this.teamid
				}).then(res => {
					console.log('完成报名',res.data)
					this.$message.success(res.msg)
					this.getUserCompetitionState()
					this.payVisible = false
				})
			},
			// 报名 
			editCompetitionTeam() {
				if (!this.baomingForm.teamName) {
					return this.$message.error('请输入团队名称')
				} else if (this.info.isShowFields!=0 && !this.baomingForm.extendField) {
					return this.$message.error('请输入天池ID')
				} else if (this.info.isShowReferrer!=0 && !this.baomingForm.referrer) {
					return this.$message.error('请输入推荐人')
				} else if (!this.baomingForm.captainName) {
					return this.$message.error('请输入队长')
				} else if (!this.checked) {
					return this.$message.error('请勾选参赛协议！')
				}
				this.baomingForm.competitionId = this.id*1
				let member = [] 
				for(let i=0;i<this.user.length;i++) {
					if(!this.user[i].name) {
						return this.$message.error(`请输入第${i+1}个队员姓名`)
					} else if(!this.user[i].tel) {
						return this.$message.error(`请输入第${i+1}个队员手机号`)
					} else if(!pattern.test(this.user[i].tel)) {
						return this.$message.error(`第${i+1}个队员手机号格式错误`)
					}
				}
				this.user.map((item) => {
					member.push([item.name,item.tel])
				})
				this.baomingForm.member = JSON.stringify(member)
				editCompetitionTeam(this.baomingForm).then(res => {
					console.log('报名',res)
					// this.$message.success(res.msg)
					this.dialogVisible = false;
					this.teamid = res.data.teamId
					if(this.info.isPay==1) {
						this.payVisible = true
						this.qrcodeScan(res.data.payUrl)
					}else {
						// this.payfinish()
					}
					this.getUserCompetitionState()
				})
			},
			// 生成二维码
			qrcodeScan(url) {
				let that = this
				that.$nextTick(() => {
					let qrcode = new QRCode(document.getElementById("qrcode"), {
						width: 200, // 二维码宽度 
						height: 200, // 二维码高度
						text: url, // 浏览器地址url
						colorDark: "#000000",
						colorLight: "#ffffff",
						correctLevel: QRCode.CorrectLevel.H,
					})
				})
			},
			// 侧边导航滚动
			scl(ele, index, id) {
				this.$parent.xiaohui(ele,index, id)
			},
			add() {
				if(this.user.length>0) {
					if(!this.user[this.user.length-1].name) {
						return this.$message.error('请输入队员姓名')
					} else if(!this.user[this.user.length-1].tel) {
						return this.$message.error('请输入队员手机号')
					} else if(!pattern.test(this.user[this.user.length-1].tel)) {
						return this.$message.error('队员手机号格式错误')
					}
				}
				let maxnum = this.info.maxCompetitionPerson-1
				if(this.user.length<maxnum) {
					this.user.push({
						name: '',
						tel: ''
					})
				}else {
					this.$message.error('最多可添加个'+maxnum+'队员！')
				}
			},
			del(index) {
				this.user.splice(index, 1)
			},
			handleChange(file, fileList) {
				this.fileList = fileList.slice(-3);
			},
			// 获取时间轴上的点
			getTimedot() {
				let arr = []
				let data = this.info.competitionProcess
				for(let i=0;i<data.length;i++) {
					arr.push(this.$util.submad(data[i].startDated),this.$util.submad(data[i].endDated))
				}
				var newarr = arr.filter((item,index) => {
					return arr.indexOf(item) === index
				})
				let noRepeat1 = this.$util.noRepeat1(newarr)
				newarr = newarr.filter((item,index) => {	
					return !noRepeat1.includes(item)
				})
				return newarr
			},
			// 时间轴上所有点
			getTimedotAll() {
				let arr = this.$util.dealDate(this.info.startDated, this.info.endDated)
				return arr
			},
			// 时间进度
			timeprocess() {
				let num;
				// 已经过去的天数
				let before = this.$util.getDiffDay(new Date(),this.info.startDated)
				// 是否大于当前日期
				let isNow = this.$util.compareDates(this.info.startDated)
				
				if(isNow<0) {
					before = 0
				}
				// 全部天数
				let all = this.$util.dealDate(this.info.startDated,this.info.endDated).length
				if(before>all) {
					num = '100%'
				} else {
					// 进度比例
					num = (before/all)*100+"%"
				}
				return num
			},
			// 三个阶段高度
			timeprocessTop(i) {
				let arr = []
				let data = this.info.competitionProcess
				// 是否开启初赛：1为开启 2为关闭
				// 是否开启决赛：1为开启 2为关闭
				if(this.info.firstCompetitionState==2 && this.info.finalCompetitionState==2) {
					data=[
						{
							"title": "赛事报名",
							"startDated": data[0].startDated,
							"endDated": data[0].endDated
						},
						{
							"title": "赛事初赛",
							"startDated": null,
							"endDated": null
						},
						{
							"title": "赛事决赛",
							"startDated": null,
							"endDated": null
						}
					]
				}else if(this.info.firstCompetitionState==1 && this.info.finalCompetitionState==2) {
					data=[
						{
							"title": "赛事报名",
							"startDated": data[0].startDated,
							"endDated": data[0].endDated
						},
						{
							"title": "赛事初赛",
							"startDated": data[1].startDated,
							"endDated": data[1].endDated
						},
						{
							"title": "赛事决赛",
							"startDated": null,
							"endDated": null
						}
					]
				}else if(this.info.firstCompetitionState==2 && this.info.finalCompetitionState==1) {
					data=[
						{
							"title": "赛事报名",
							"startDated": data[0].startDated,
							"endDated": data[0].endDated
						},
						{
							"title": "赛事初赛",
							"startDated": null,
							"endDated": null
						},
						{
							"title": "赛事决赛",
							"startDated": data[1].startDated,
							"endDated": data[1].endDated
						}
					]
				}else {
					data=[
						{
							"title": "赛事报名",
							"startDated": data[0].startDated,
							"endDated": data[0].endDated
						},
						{
							"title": "赛事初赛",
							"startDated": data[1].startDated,
							"endDated": data[1].endDated
						},
						{
							"title": "赛事决赛",
							"startDated": data[2].startDated,
							"endDated": data[2].endDated
						}
					]
				}
				console.log('data',data)
				for (let i = 0; i < data.length; i++) {
					arr.push(data[i].startDated, data[i].endDated)
				}
				// for (let i = 0; i < arr.length; i++) {
				// 	let tomorrow = this.$util.tomorrow(arr[i], 1).replace(/\-/g, ".");
				// 	let yesterday = this.$util.tomorrow(arr[i], -1).replace(/\-/g, ".");
				// 	if(arr[i]!=null){
				// 		for (let j = i + 1; j < arr.length; j++) {
				// 			if(arr[j]!=null){
				// 				let today = this.$util.submad(arr[j])
				// 				if (today == tomorrow || today == yesterday) {
				// 					arr[j] = arr[i]
				// 				}
				// 			}
				// 		}
				// 	}
				// }
				
			
				data = [{
						startDated: arr[0],
						endDated: arr[1]
					},
					{
						startDated: arr[2],
						endDated: arr[3]
					},
					{
						startDated: arr[4],
						endDated: arr[5]
					}
				]
			
				let h = 440;
			
				let style = {};
			
				// 报名开始日期-报名结束日期  间隔
				let baoming = this.$util.getDiffDay(data[i].startDated, data[i].endDated) || 1
				// 报名开始日期-活动开始日期
				let top = this.$util.getDiffDay(this.info.startDated, data[i].startDated)
			
				// 全部天数
				let all = this.$util.dealDate(this.info.startDated, this.info.endDated).length
				let zwH = (h / all / 2)
				if (data[i].startDated == this.info.startDated) {
					return style = {
						'top': "0px",
						'height': ((baoming / all) * h) + zwH + "px",
					}
				}
				if (data[i].endDated == this.info.endDated) {
					return style = {
						'top': (top / all) * h + zwH + "px",
						'height': ((baoming / all) * h) + (zwH) + "px",
					}
				}
				style = {
					'top': (top / all) * h + zwH + "px",
					'height': ((baoming / all) * h) + "px",
				}
				return style
			},
			// 时间点是否高亮
			shijianbi(time) {
				// console.log('时间点是否高亮',time,this.$util.getYMD())
				let time1 = time.replace(/\./g, "");
				let time2 = this.$util.getYMD().replace(/\./g, "");
				if(time1>time2) {
					return false
				}
				return true;
			},
			// 时间轴文字描述位置
			timeprocessDiv(i) {
				let that = this
				let style = null
				let defaultH = Number(10)
				let h = document.getElementsByClassName('process1Div')[0].clientHeight
				let p1 = this.timeprocessTop(0).top.slice(0, -2)
				let p2 = this.timeprocessTop(1).top.slice(0, -2)
				let p3 = this.timeprocessTop(2).top.slice(0, -2)
				if (p1 == 0 && p2 == 0 && p3 == 0 && h == 0) {
					p1 = this.timeprocessTop(0).top.slice(0, -2)
					p2 = this.timeprocessTop(1).top.slice(0, -2)
					p3 = this.timeprocessTop(2).top.slice(0, -2)
					h = 50
				}
				p1 = Number(p1)
				p2 = Number(p2)
				p3 = Number(p3)
				h = Number(h)
				// 初赛
				if (i == 1) {
					console.log('初赛', p1, p2, p3, h, i)
					// 初赛 && 报名
					if (p1 + h >= p2) {
						style = {
							'margin-top': (p1 + h - p2 + defaultH) + "px"
						}
					} else {
						style = {
							'margin-top': "0px"
						}
					}
					this.style1 = style
					return false;
				}
				// 决赛
				if (i == 2) {
					console.log('决赛', p1, p2, p3, h, i)
					// 决赛 && 报名
					if (p1 + h >= p3) { //决赛==报名
						if (p2 + h >= p3) { //决赛==报名==初赛
							console.log('。。。')
							style = {
								'margin-top': (p2 - p3 + (h + defaultH) * 2) + "px",
								'opacity': 1
							}
						} else { // 决赛==报名！=初赛
							console.log('决赛拉')
							style = {
								'margin-top': (p2 + h + defaultH) + "px",
								'opacity': 1
							}
						}
					} else if (p2 + h >= p3) { // 决赛==初赛
						console.log('决赛==初赛')
						// 初赛 && 决赛
						style = {
							'margin-top': parseFloat((p2 + h) - p3 + defaultH) + "px",
							'opacity': 1
						}
					} else {
						console.log('决赛--')
						style = {
							'margin-top': "0px",
							'opacity': 1
						}
					}
					this.style2 = style
				}
			}
					
			
		}
	}
</script>

<style lang="scss" scoped>
	.process3Div {
		opacity: 0;
	}
	.banner {
		padding: 90px 0 140px;

		.title {
			color: rgba(238, 238, 238, 1);
			font-size: 60px;
			text-align: left;
			line-height: 1.5;
		}

		.subtit {
			color: rgba(255, 255, 255, 1);
			font-size: 30px;
			text-align: left;
			line-height: 1.5;
			width: 70%;
		}

		.btn {
			display: flex;
			margin-top: 60px;

			span {
				padding: 20px 30px;
				border-radius: 5px;
				font-size: 20px;
			}

			span:nth-child(1) {
				background: #9dc3f7;
				color: #fff;
			}

			span:nth-child(2) {
				background: none;
				color: #9dc3f7;
			}
		}
	}
	
	.finish {
		// font-size: 18px;
		color: #909090;
		display: flex;
		align-items: center;
		margin: 15px 0 10px;
		margin-right: 30px;
		height: 40px;
		i {
			color: #909090;
			margin-right: 5px;
			font-size: 20px;
		}
	}
	
	.btn-block {
		display: flex;
	}
	
	.baoming {
		background: #9dc3f7;
		color: #eee;
		height: 40px;
		display: inline-flex;
		padding: 0 20px;
		cursor: pointer;
		align-items: center;
		justify-content: center;
		margin-top: 15px;
		border-radius: 5px;
		margin-right: 30px;
	}
	
	.entry {
		background: none;
		height: 40px;
		display: inline-flex;
		cursor: pointer;
		align-items: center;
		justify-content: center;
		margin-top: 15px;
		border-radius: 5px;
		a {
			align-items: center;
			width: 100%;
			height: 100%;
			color: #9dc3f7;
			line-height: 40px;
		}
	}

	.content {
		.navlist {
			border-top: 1px solid rgba(38, 38, 38, 1);
			border-bottom: 1px solid rgba(38, 38, 38, 1);
			height: 60px;
			color: #fff;
			font-size: 20px;

			.nav {
				display: flex;
				height: 100%;

				p {
					flex: 1;
					display: flex;
					align-items: center;
					justify-content: center;
				}
			}
		}
		
		.right_block {
			background: rgba(64, 64, 64, 0.4);
			padding: 15px;
			border-radius: 10px;
		}
		
		.navcon {
			display: flex;
			// margin-top: 30px;
			padding-top: 30px;

			.left {
				width: 60px;
				min-width: 60px;
				margin-right: 40px;

				p {
					color: #A3A3A3;
					font-size: 14px;
					height: 32px;
					line-height: 32px;
					cursor: pointer;
				}

				p.act {
					color: #fff;

					span {
						display: inline-block;
						border-bottom: 1px solid #9dc3f7;
					}
				}
			}

			.center {
				flex: 1;

				.top {
					display: flex;
					justify-content: space-between;

					img {
						width: 260px;
						height: 180px;
						border-radius: 10px;
					}
				}

				.title {
					font-size: 34px;
					font-weight: 600;
					line-height: 1.4;
				}

				.time {
					margin-top: 8px;
				}

				.price,
				.time {
					color: #DDD;
					line-height: 2;
					font-size: 14px;
				}

				.step {
					.step_title {
						font-size: 18px;
						font-weight:600;
						color: #EEEEEE;
						line-height: 2;
						margin-top: 35px;
					}

					.step_con {
						font-size: 14px;
						color: #A3A3A3;
						line-height: 1.8;
					}
				}
			}

			.right {
				width: 250px;
				min-width: 250px;
				margin-left: 40px;

				.finish {
					font-size: 18px;
					color: #1097f0;
					display: flex;
					align-items: center;
					margin: 15px 0 10px;

					i {
						color: #1097f0;
						margin-right: 5px;
						font-size: 20px;
					}
				}

				.title {
					padding: 10px 15px;
					border-radius: 5px;
					font-size: 18px;
					display: inline-block;
					background: #9dc3f7;
					color: #fff;
					cursor: pointer;
				}

				.subtit {
					font-size: 16px;
					color: #fff;
					padding: 10px 0 20px;
					display: flex;
					align-items: center;
				}

				.timeline {
					color: #8B8B8B;
					font-size: 16px;
					display: flex;
					flex-direction: column;
					position: relative;
					.stepline {
						height: 440px;
						border-left: 1px solid #404040;
						margin: 10px 20px;
						position: relative;
						.zw {
							flex: 1;
							display: flex;
							align-items: center;
							p {
								display: inline-flex;
							}
							.dian {
								display: inline-block;
								margin-right: 10px;
								z-index: 100;
								font-size: 12px;
								margin-left: 0.5px;
								display: flex;
								align-items: center;
								justify-content: center;
								border-radius: 50%;
								overflow: hidden;
							}
							.icon-Raidobox-xuanzhong {
								width: 10px;
								height: 10px;
								color: #0067DD;
								background: #151515;
							}
							.icon-dian {
								width: 5px;
								height: 5px;
								color: #909090;
								border: 2px solid #151515;
								margin-left: 2.5px;
							}
						}
						.zw:first-child {
							align-items: flex-start;
						}
						.zw:last-child {
							align-items: flex-end;
						}
						.dot {
							height: 100%;
							margin-left: -5.5px;
							display: flex;
							flex-direction: column;
							justify-content: space-between;
						}
						
						.process {
							background: #0067DD;
							width: 2px;
							border-radius: 5px;
							height: 50%;
							position: absolute;
							left: -1px;
							top: 0;
						}
						.process2,
						.process3,
						.process1 {
							position: absolute;
							padding-left: 20px;
							width: 100%;
							box-sizing: border-box;
							p {
								background: #80D1C8;
								width: 5px;
								border-radius: 5px;
								height: 100%;
								margin-right: 10px;
								float: left;
							}
							div {
								padding-left: 40px;
								display: flex;
								flex-direction: column;
								flex: 1;
								line-height: 1.3;
								text-align: left;
								span {
									color:#80D1C8;
									font-size: 16px;
									i {
										margin-left: 10px;
									}
								}
							}
						}
						.process2 {
							padding-left: 40px;
							p {
								background: #FFE76F;
							}
							div {
								padding-left: 20px;
								span {
									color:#FFE76F;
								}
							}
						}
						.process3 {
							padding-left: 60px;
							p {
								background: #FC9927;
							}
							div {
								padding-left: 0px;
								span {
									color:#FC9927;
								}
							}
						}
					}
					

					.date {
						display: flex;
						flex-direction: column;
						justify-content: center;
					}

					.line {
						border-left: 1px solid #0067DD;
						position: relative;
						height: 240px;
						margin-left: 20px;
						padding-left: 30px;

						.sub {
							color: #fff;
							line-height: 2;
						}

						span {
							position: absolute;
							z-index: 2;
							top: -0px;
							left: -11px;
							font-size: 22px;
							display: inline-block;
							background-color: #151515;
							border-radius: 50%;
							color: #0067DD;
						}

						div {
							position: absolute;
							width: 6px;
							background: #0067DD;
							height: 0;
							left: -4px;
							z-index: 1;
							border-radius: 10px;
						}
					}

					.act {
						span {
							color: #0067DD;
							font-size: 20px;
						}

						div {
							position: absolute;
							width: 6px;
							background: #0067DD;
							height: 100px;
							left: -4px;
							z-index: 1;
							border-radius: 10px;
						}
					}
				}
			}

		}
	}

	.ml {
		margin-left: auto;
	}

	::v-deep {
		.el-dialog {
			background: #222222;
			border: 1px solid #404040;
			border-radius: 10px;

			.el-dialog__header {
				height: 60px;
				padding: 0 25px;
				display: flex;
				align-items: center;
				border-bottom: 1px solid #404040;

				.el-dialog__title {
					color: #fff;
				}

				.el-dialog__headerbtn {
					color: #fff;
				}
			}

			.el-dialog__body {
				padding: 16px 25px 50px;
				border-bottom: 1px solid #404040;

				.el-upload {
					width: 100%;
				}

				.text {
					font-size: 14px;
					color: #A3A3A3;
					display: flex;
					align-items: center;
					justify-content: space-between;
					padding: 10px 0 0;
					
					span {
						a{
							text-decoration: underline;
							color: #1097f0;
						}
					}
				}

				.mianze {
					background: #404040;
					padding: 20px;
					color: #eee;
					font-size: 14px;
					line-height: 2;
					border-radius: 10px;
					text-align: left;
				}

				.upd {
					background: #404040;
					border: 1px solid #BBBBBB;
					border-radius: 10px;
					width: 100%;
					padding: 40px 0 15px;
					color: #eee;
					font-size: 16px;

					p {
						display: flex;
						align-items: center;
						justify-content: center;

						i {
							color: #9dc3f7;
							font-size: 28px;
							margin-right: 8px;
						}
					}

					.el-upload__tip {
						font-size: 14px;
						line-height: 2;
						color: #A3A3A3;
					}
				}

				.upd1 {
					background: #404040;
					border: 1px solid #BBBBBB;
					border-radius: 10px;
					width: 100%;
					height: 60px;
					display: flex;
					align-items: center;
					justify-content: space-between;
					color: #eee;
					font-size: 16px;
					padding: 0 20px;

					img {
						margin-right: 10px;
					}

					.el-icon-close {
						margin-left: auto;
						font-size: 20px;
					}
				}

				.newtext {
					padding-left: 140px;
				}

				.adduser {
					margin-top: 10px;
					padding: 10px 20px;
					border-radius: 10px;
					background: #404040;
					display: flex;
					align-items: center;

					div {
						flex: 1
					}

					.inputdiv {
						display: flex;
						align-items: center;
						margin: 10px 0;
						font-size: 16px;

						span {
							display: inline-block;
							width: 100px;
							color: #eee;
						}
					}

					.el-icon-delete-solid {
						font-size: 20px;
						margin-left: 30px;
						color: #eee;
					}
				}

				.dia {
					.tit {
						font-size: 30px;
						color: #eee;
					}

					.input {
						line-height: 2;
						color: #fff;
						margin-top: 20px;
						margin-bottom: 8px;
						font-size: 16px;
					}

					.el-textarea__inner,
					.el-input__inner {
						color: #eee;
						background: none;
						border: 1px solid #BBBBBB;
					}

					.bn {
						background: none;
						color: #1097f0;
						border: 1px solid #1097f0;
						margin-top: 10px;
					}
				}
			}

			.el-dialog__footer {
				padding: 0 25px;
				height: 90px;
				display: flex;
				align-items: center;

				.dialog-footer {
					display: flex;
					width: 100%;
					align-items: center;
					justify-content: space-between;
				}

				.el-checkbox__label {
					color: #fff;
				}

				.can {
					background: none;
					color: #fff;
					border: none;
				}

				.sub {
					background: #9dc3f7;
					color: #fff;
					border: none;
					span {
						display: flex;
						align-items: center;
						span {
							margin-left: 5px;
						}
					}
				}
			}
		}
	}

	.flex {
		display: flex;
		width: 100%;
		align-items: center;
		margin-top: 20px;

		div.input {
			width: 140px;
			min-width: 140px;
			margin-top: 0 !important;
			margin-bottom: 0 !important;
			line-height: 100%;

			.el-input__inner {
				flex: 1;
				margin-bottom: 0;
			}
		}
	}
	
	.pay {
		display: flex;
		align-items: center;
		color: #bbb;
		line-height: 1.5;
		flex-direction: column;
		justify-content: center;
		#qrcode {
			margin-top: 10px;
			width: 200px;
			height: 200px;
			margin: 20px auto 10px;
			border: 10px solid #fff;
			border-radius: 5px;
		}
		.text {
			display: flex;
			flex-direction: column;
			align-items: center;
		}
		p {
			text-align: center;
		}
		.an {
			cursor: pointer;
			display: inline-block;
			border: 1px solid #bbb;
			border-radius: 5px;
			padding: 3px 10px;
			margin-top: 15px;
			font-size: 14px;
		}
	}

	@media screen and (max-width: 750px) {
		
		.finish {
			margin-right: 0;
		}
		
		.banner {
			padding: 2rem 0;

			.title {
				font-size: 2rem;
			}

			.subtit {
				font-size: 1rem;
				width: 100%;
			}

			.btn {
				margin-top: 2rem;
				justify-content: space-between;

				span {
					font-size: 1rem;
					padding: 0;
					width: 49%;
					height: 3rem;
					display: flex;
					align-items: center;
					justify-content: center;
				}
			}
		}
		

		.trends {
			// .title {
			// 	font-size: 1.2rem;
			// 	display: flex;
			// 	align-items: center;
			// 	justify-content: space-between;
			// 	line-height: 3;

			// 	span {
			// 		font-size: 1rem;
			// 	}
			// }

			.list {
				display: flex;
				flex-direction: column;
				border: 1px solid #404040;
				padding: 10px;
				border-radius: 10px;
				margin-bottom: 20px;

				img {
					width: 100%;
					border-radius: 10px;
				}

				.title {
					font-size: 1.8rem;
					line-height: 2;
					padding-top: 5px;
				}

				.time {
					color: #a3a3a3;
					font-size: 1rem;
					line-height: 1.5;
				}
			}
		}

	}
</style>

<style lang="scss" scoped>
	
	@media screen and (max-width: 750px) {
		.trends {
			.list {
				display: flex;
				flex-direction: column;
				margin-top: 10px;
				margin-bottom: 10px;
				img {
					width: 100%;
				}
				.title {
					font-size: 1.8rem;
					line-height: 2;
					padding-top: 5px;
				}
				.time {
					color: #a3a3a3;
					font-size: 1rem;
					line-height: 1.5;
				}
			}
		}
		.baoming { 
			background: #9dc3f7;
			color: #eee;
			height: 40px;
			display: flex;
			align-items: center;
			justify-content: center;
			margin-top: 15px;
			border-radius: 5px;
		}
		.step {
			display: flex;
		
			.step1 {
				flex: 1;
				position: relative;
				span {
					color: #0067DD;
					font-size: 20px;
					position: absolute;
					left: 50%;
					margin-left: -10px;
					z-index: 9;
					display: flex;
					width: 20px;
					height: 20px;
					background: #151515;
					border-radius: 50%;
				}
				p.act {
					height: 8px;
					border-radius: 10px;
					width: 50%;
					background: #9dc3f7;
					top: 6px;
					width: 100%;
					position: absolute;
				}
			}
			.step1:first-child {
				span{
					left: 0;
				}
			}
			.step1:last-child {
				span{
					right: 0;
					margin-left: auto;
				}
			}
		}
		.buzhou {
			position: relative;
			margin-top: 15px;
			margin-left: 10px;
		}
		.bz {
			background: rgba(64, 64, 64, 0.4);
			padding: 1rem;
			height: 4rem;
			.title {
				display: flex;
				align-items: center;
				font-size: 1rem;
				img {
					margin-right: 8px;
				}
				p {
					flex: 1;
					color: #9dc3f7;
				}
				span {
					color: #909090;
				}
			}
		}
		.stepline {
			position: absolute;
			background: #9dc3f7;
			width: 100%;
			height: 1px;
			top: 10px;
		}
		.nr {
			.title {
				font-size: 1.5rem;
				padding: 2.5rem 0 1.5rem;
			}
			.xq {
				font-size: 1rem;
				line-height: 1.5;
				color: #A3A3A3;
			}
		}
		.detailbz {
			.step {
				flex-direction: column;
			}
		}
		.timeline {
				color: #8B8B8B;
				font-size: 16px;
				margin-top: 2rem;
				.date {
					margin-bottom: 10px;
					display: flex;
					flex-direction: column;
					justify-content: center;
					span {
						font-size: 22px;
						color: #0067DD;
						margin-left: 10px;
					}
				}
				.line {
					border-left: 1px solid #0067DD;
					position: relative;
					height: 22vh;
					margin-left: 20px;
					padding-left: 30px;
					.sub {
						color: #fff;
						line-height: 2;
					}
					span {
						position: absolute;
						z-index: 2;
						top: -0px;
						left: -11px;
						font-size: 22px;
						display: inline-block;
						background-color: #151515;
						border-radius: 50%;
						color: #0067DD;
					}
					div {
						position: absolute;
						width: 6px;
						background: #0067DD;
						height: 0;
						left: -4px;
						z-index: 1;
						border-radius: 10px;
					}
				}
				.act {
					span {
						color: #0067DD;
						font-size: 20px;
					}
					div {
						position: absolute;
						width: 6px;
						background: #0067DD;
						height: 100px;
						left: -4px;
						z-index: 1;
						border-radius: 10px;
					}
				}
			}
		.detailbz {
			background: #151515;
			position: fixed;
			width: 100%;
			height: 100vh;
			top: 0;
			left: 0;
			z-index: 10;
			.icon-guanbi {
				text-align: right;
				margin-top: 30px;
				font-size: 2rem;
				display: block;
				padding-right: 20px;
			}
			.title {
				font-size: 1.5rem;
				margin: 1rem 20px;
			}
		}
	}
	.dialog-footer {
		.left {
			display: flex;
			align-items: center;
			height: 20px;
		}
	}
	.xyclass {
		font-size: 14px;
		margin-left: 5px;
		cursor: pointer;
		text-decoration: underline;
		margin-top: -2px;
	}
	.el-checkbox {
		display: inline-flex;
		align-items: center;
	}
	::v-deep {
		.xieyi {
			.el-dialog .el-dialog__body {
				padding: 30px 20px;
			}
		}
	}
	
	.xiyihtml {
		color: #fff;
		line-height: 1.5;
	}
	
	.rmt10 {
		margin-top: 10px;
		a {
			margin-bottom: 10px;
			display: block;
		}
	}
	.rmt30 {
		margin-top: 30px;
		a {
			margin-bottom: 30px;
			display: block;
		}
	}
	.downloadbtn {
		background: #fc9927;
		padding: 0 20px;
		border-radius: 50px;
		height: 34px;
		color: #fff;
		display: inline-flex;
		align-items: center;
		font-size: 14px;
		cursor: pointer;
	}
	.match {
		color: #A3A3A3;
		font-size: 14px;
		line-height: 2;
	}
	.matchbtn {
		margin-top: 20px;
		p {
			cursor: pointer;
			background: #7C3AED;
			color: #fff;
			font-size: 14px;
			border-radius: 4px;
			margin-right: 15px;
			display: inline-flex;
			align-items: center;
			height: 36px;
			padding: 0 20px;
		}
	}
	::v-deep{
		.mat {
			.el-dialog__body {
				border-bottom: none !important;
				padding-bottom: 30px;
			}
		}
		.code {
			.el-dialog__body {
				padding-top: 30px;
				padding-bottom: 30px;
			}
			.codef {
				width: 100%;
				display: flex;
				justify-content: space-between;
				align-items: center;
				p {
					font-size: 14px;
					color: #A3A3A3;
				}
			}
		}
	}
	.opacity3 {
		background-color: rgba(237,108,35,.5);
		color: rgba(255,255,255,.5);
		cursor: no-drop;
	}
	
	.upcode {
		background: #404040;
		border-radius: 5px;
		height: 60px;
		color: #EEEEEE;
		display: flex;
		align-items: center;
		padding: 0 10px;
		.el-icon-close {
			margin-left: auto;
			cursor: pointer;
		}
		p {
			padding: 0 10px;
		}
	}
	.icon-bisaihuodong {
		font-size: 20px;
		margin-right: 8px;
	}
	.icon-icon {
		font-size: 20px;
		margin-right: 8px;
	}
</style>